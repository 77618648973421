import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserChatHistory, OpenAIandGeminiUserDataSave, ReGenerateToken, UploadToBucket } from "../../redux/auth/authSlice";
import Comment from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import { Select, Upload, Button } from "antd";
import Format from "../../Utils/helpers/Format";
import ProjectWlogo from "../../../src/Utils/Images/ProjectWlogo.svg";
import {
  VideoCameraOutlined,
  PictureOutlined,
  AudioOutlined,
  CopyOutlined,
  CheckOutlined,
  UploadOutlined,
  VideoCameraFilled,
  VideoCameraAddOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Components/Common/Common";
import UserHistory from "./UserHistory";
const ChatAI = () => {
  const { Option } = Select;
  const { S3BucketImage, userHistory } = useSelector((state) => state?.counter);
  const dispatch = useDispatch();
  const defaultState = {
    text: false,
    image: false,
    audio: false,
    video: false,
    imagevideo: false,
    audiovideo: false,
  };
  const image = {
    init_image: "",
    image_prompt: "",
    motion_bucket_id: 10,
    num_frames: 25,
    output_type: "mp4",
  };
  const [isRecording, setIsRecording] = useState(false);
  const [audioBase64, setAudioBase64] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [aiagentinput, setAiagentinput] = useState("");
  const [selecticon, setSelectIcon] = useState({ ...defaultState, text: true });
  const [activeOption, setActiveOption] = useState("text");
  const [audioAnswer, setAudioAnswer] = useState(null);
  const [selectedItem, setSelectedItem] = useState("text");
  const [userchatHistory, setUserChatHistory] = useState([]);
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);

  console.log(userchatHistory,"userchathistoryyyyyyyyyyyyyyyy");

  const email = GetDecryptEmailformat();
  const answerScroll = useRef(null);
  const answerRefs = useRef([]);

  const text = "Chat (T - T)";
  const imagetext = "Generate image (T - I)";
  const audiotext = "Voice Chat (V - T)";
  const videotext = "Text to Video (T - V)";
  const Imagetovideotext = "Image to Video (I - V)";
  const Audiotovideotext = "Audio to  Video (A - V)";

  useEffect(() => {
    const type = "text";
    dispatch(GetUserChatHistory({ dispatch, email, type }));
  }, []);

  useEffect(() => {
    return () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };
  }, [currentAudio]);


  useEffect(() => {
    if (userHistory) {
      setUserChatHistory(userHistory);
    }
  }, [userHistory]);

  useEffect(() => {
    if (audioBase64 && audioBase64?.length > 0) {
      if (selecticon?.audiovideo) {
        HandleAudioToVideo();
      } else {
        HandleAudioGeneration(audioBase64);
      }
    }
  }, [audioBase64]);

  useEffect(() => {
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }
  }, [userchatHistory]);

  useEffect(() => {
    if (audioAnswer) {
      setUserChatHistory((prevState) => {
        let previousResponse = [...prevState];

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          ...audioAnswer,
        };

        return previousResponse;
      });
    }
  }, [audioAnswer]);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const HandleChange = (e) => {
    setAiagentinput(e?.target?.value);
  };

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Media Devices not supported");
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      audioChunksRef.current = [];

      const base64String = await convertBlobToBase64(audioBlob);
      setAudioBase64(base64String);
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
      setIsRecording(false);
    }
  };

  const playAudio = (generatedAudio) => {
    if (currentAudio) {
      currentAudio.pause();
      setCurrentAudio(null);
    }

    const audio = new Audio(`data:audio/mp3;base64,${generatedAudio}`);
    audio.play();
    setCurrentAudio(audio);

    audio.addEventListener("ended", () => {
    setCurrentAudio(null);
    });
  };
  const AnimatedText = ({ text, hasAudio, audio }) => {
    const [displayText, setDisplayText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (currentIndex < text?.length) {
          setDisplayText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex(function (prevIndex) {
            return prevIndex + 1;
          });
        } else {
          setUserChatHistory((prevState) => {
            let newState = [...prevState];
            newState[newState?.length - 1] = {
              ...newState[newState?.length - 1],
              animate: false,
            };
            return newState;
          });

          clearInterval(interval);
        }
      }, 10);
      return () => clearInterval(interval);
    }, [currentIndex, text]);

    if (hasAudio) {
      return (
        <div className="animated-text">
          {displayText}
          {currentIndex >= text?.length && (
            <span style={{ color: "red" }} onClick={() => playAudio(audio)}>
              Play Again
            </span>
          )}
        </div>
      );
    }

    return <div className="animated-text">{displayText}</div>;
  };

  const WaveLoader = () => {
    return (
      <div className="loading">
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
      </div>
    );
  };

  const Question = ({ hasAudio, userAudio, question, voiceprompt, base64audio }) => {
    if (hasAudio || (voiceprompt && base64audio)) {
      return (
        <audio src={`data:audio/wav;base64,${userAudio || voiceprompt}`} controls>
          Your browser does not support the <code>audio</code> element.
        </audio>
      );
    }

    return question;
  };

  const pollForVideoAvailability = async (videoUrl) => {
    const maxRetries = 100; // Maximum number of polling attempts
    const delay = 1000; // 1 second between each poll
    let attempt = 0;

    while (attempt < maxRetries) {
      try {
        const response = await fetch(videoUrl, { method: "HEAD" }); // Check if the video exists

        if (response.ok) {
          const updatedResponse = {
            answer: videoUrl,
            showStatus: true,
          };

          // Update the UI or state to show the video (implement this in your app context)
          setUserChatHistory((prevHistory) => {
            const newHistory = [...prevHistory];
            newHistory[newHistory.length - 1] = {
              ...newHistory[newHistory.length - 1],
              ...updatedResponse,
            };
            return newHistory;
          });

          const type = "video";
          dispatch(GetUserChatHistory({ dispatch, email, type }));
          setSelectedItem("video");

          return; // Exit the function if the video is available
        }
      } catch (error) {
        console.error("Error checking video availability:", error);

        // Handle failure to fetch and update state accordingly
        setUserChatHistory((prevHistory) => {
          const newHistory = [...prevHistory];
          newHistory[newHistory.length - 1] = {
            ...newHistory[newHistory.length - 1],
            answer: "Failed to generate video.",
          };
          return newHistory;
        });
      }

      // Wait for the specified delay before retrying
      await new Promise((resolve) => setTimeout(resolve, delay));
      attempt++;
    }

    console.error("Video did not become available within the expected time");

    // Handle timeout scenario
    setUserChatHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      newHistory[newHistory.length - 1] = {
        ...newHistory[newHistory.length - 1],
        answer: "Video did not become available within the expected time.",
      };
      return newHistory;
    });
  };

  const Answer = ({ userVideo, userImage, userImageprompt, answer, animateText, hasImage, hasAudio, hasVideo, audio, status, answerRefs, index, uservoiceresponse }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [buttonText, setButtonText] = useState("");
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    // const [Loader, setLoader] = useState([]);

    // console.log(Loader,"lllllllllllll");
    // useEffect(() => {
    //   if (userVideo && userVideo.length > 0) {
    //     const videoUrls = userchatHistory.map((item, index) => ({
    //       [index + 1]: item.video_url,
    //     }));

    //     // Update only the last element of the array to include the loader
    //     videoUrls[videoUrls.length - 1] = {
    //       ...videoUrls[videoUrls.length - 1],
    //       answer: "", // Clear the answer or keep it empty while loading
    //       Loader: "Loading...", // Add the loader indicator
    //     };

    //     setLoader(videoUrls);
    //   }
    // }, [userVideo, userchatHistory]);

    const handlePlayPause = () => {
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          if (currentAudio) {
            currentAudio.pause();
          }
          audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
    };

    useEffect(() => {
      if (audioRef.current) {
        audioRef.current.addEventListener("ended", () => {
          setIsPlaying(false);
        });
      }
    }, []);

    if (!answer && (hasAudio || hasVideo)) {
      return <WaveLoader />;
    }

    if (!status && !answer) {
      return (
        <div className="chat-answer">
          <Comment height="50" width="50" color="#4b49ac" visible={true} wrapperStyle={{}} backgroundColor="#F4442E" ariaLabel="comment-loading" wrapperClass="comment-wrapper" />
        </div>
      );
    }

    if (hasAudio && hasVideo) {
      return (
        <video width="400" controls className="video_resp_css">
          <source src={answer} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      );
    }

    if (hasAudio && answer) {
      return (
        <div style={{ color: "black" }}>
          {answer}
          {audio && (
            <div>
              <audio ref={audioRef} src={`data:audio/mp3;base64,${audio}`} />
              <i onClick={() => handlePlayPause(audio)} style={{ cursor: "pointer" }} className={isPlaying ? "fa-regular fa-circle-pause" : "fa-regular fa-circle-play"}></i>
            </div>
          )}
        </div>
      );
    }

    if (uservoiceresponse && answer) {
      return (
        <div style={{ color: "black" }}>
          {answer}
          {uservoiceresponse && (
            <div>
              <audio ref={audioRef} src={`data:audio/wav;base64,${uservoiceresponse}`} />
              <i
                onClick={() => handlePlayPause(uservoiceresponse)}
                style={{ cursor: "pointer" }}
                className={isPlaying ? "fa-regular fa-circle-pause" : "fa-regular fa-circle-play"}
              ></i>
            </div>
          )}
        </div>
      );
    }

    if (userVideo && answer) {
      return (
        <>
          <div>
            <div>
              <video width="400" controls className="video_resp_css">
                <source src={userVideo} type="video/mp4" />
                Your browser does not support HTML video.
              </video>
            </div>
          </div>
        </>
      );
    }

    if (hasVideo && answer && status) {
      return (
        <video width="400" controls className="video_resp_css">
          <source src={answer} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      );
    }

    if (hasImage || userImage || userImageprompt) {
      return <img height={700} src={`data:image/png;base64,${answer || userImage || userImageprompt}`} alt="Image" />;
    }

    if (animateText) {
      return (
        <div className="chat-answer ">
          <AnimatedText text={answer} />
        </div>
      );
    }

    if (answer && answer?.length > 0) {
      const copyToClipboard = () => {
        navigator.clipboard
          .writeText(answer)
          .then(() => {
            setButtonText("Copied");
            setIsCopied(true);
            setTimeout(() => {
              setButtonText("");
              setIsCopied(false);
            }, 2000);
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      };

      return (
        <div className="chat-wrapper">
          <button onClick={copyToClipboard} className="copy-button">
            {isCopied ? <CheckOutlined /> : <CopyOutlined />}
            {buttonText}
          </button>
          <div className="chat-container">
            <div
              className="chat-answer"
              ref={(el) => {
                answerRefs.current[index] = el;
              }}
            >
              <Format answer={answer} isShare={false} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="chat-answer">
        <Comment height="50" width="50" color="#4b49ac" visible={true} wrapperStyle={{}} backgroundColor="#F4442E" ariaLabel="comment-loading" wrapperClass="comment-wrapper" />
      </div>
    );
  };

  const HandleFileChange = (fileList) => {
    if (!fileList || !fileList.length || !fileList[0]?.originFileObj) {
      console.error("Invalid file list or file object");
      return;
    }

    const fileObject = fileList[0].originFileObj;

    const obj = {
      file: fileObject,
      file_name: fileObject.name,
      file_type: fileObject.type,
    };

    if (obj.file && obj.file_name && obj.file_type) {
      dispatch(UploadToBucket({ obj }));
    } else {
      console.error("File details are missing");
    }
  };

  const HandleTexttoText = (e) => {
    e?.preventDefault();
    setAiagentinput("");

    let previousResponse = [...userchatHistory];

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: false,
    });
    setUserChatHistory(previousResponse);

    const email = GetDecryptEmailformat();

    const reference_number = GetDecryptReferenceNumberformat();

    async function query() {
      setIsGeneratingResponse(true);
      try {
        let form_data = new FormData();
        form_data.append("text", aiagentinput);
        form_data.append("user_email", email);
        form_data.append("user_reference_number", reference_number);

        const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/ai_agent_input`, {
          method: "POST",
          body: form_data,
        });

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.message,
            animate: false,
          };
          setUserChatHistory(previousResponse);

          return;
        }

        if (result?.message === "Rate limit exceeded") {
          const updatedResponse = [...previousResponse];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: result.tips,
            animate: false,
          };

          setUserChatHistory(updatedResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: userchatHistory?.response_text,
          animate: true,
          showStatus: true,
        };

        setUserChatHistory(previousResponse);

        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }

        let numberdata = result.total_cost;

        if (result) {
          let obj = {
            email: email,
            reference_number: reference_number,
            total_cost: parseFloat(numberdata),
            service: "OPENAI",
          };
          dispatch(OpenAIandGeminiUserDataSave({ obj, dispatch }));
          const type = "text";
          dispatch(GetUserChatHistory({ dispatch, email, type }));
          setSelectedItem("text");
        }
      } catch (error) {
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate response.",
          animate: false,
        };
        setUserChatHistory(updatedResponse);

        toast.error(error);
      }
      finally {
        setIsGeneratingResponse(false);
      }
    }

    query().then((response) => {
      return response;
    });
  };

  const HandleAudioGeneration = async (audiobase64) => {
    let previousResponse = [...userchatHistory];
    const email = GetDecryptEmailformat();

    const reference_number = GetDecryptReferenceNumberformat();

    let audioobj = {
      audio: audiobase64,
      user_email: email,
      user_reference_number: reference_number,
    };

    previousResponse.push({
      answer: "",
      hasAudio: true,
      userAudio: audiobase64,
    });

    setUserChatHistory(previousResponse);

    try {
      const responses = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/voice_chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(audioobj),
      });

      const result = await responses.json();

      if (result?.error) {
        const answerResp = {
          answer: result?.error,
          audio: null,
        };

        setAudioAnswer(answerResp);
        return;
      }

      const answerResp = {
        answer: result?.ai_response || userchatHistory,
        audio: result.audio,
        showStatus: true,
      };

      setAudioAnswer(answerResp);

      if (result?.status === 200) {
        playAudio(result.audio);
        const type = "voice";
        dispatch(GetUserChatHistory({ dispatch, email, type }));
        setSelectedItem("voice");
      }

      if (answerScroll.current) {
        answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
      }
    } catch (error) {
      const answerResp = {
        answer: "Something went wrong please try again!",
        audio: null,
      };
      const updatedResponse = [...previousResponse];
      updatedResponse[updatedResponse.length - 1] = {
        ...updatedResponse[updatedResponse.length - 1],
        answer: "Failed to generate response.",
      };

      setUserChatHistory(updatedResponse);
      setAudioAnswer(answerResp);
      toast.error(error);
    }
  };

  const HandleTexttoImage = (e) => {
    e?.preventDefault();
    setAiagentinput("");

    let previousResponse = [...userchatHistory];

    previousResponse[previousResponse?.length - 1] = {
      ...previousResponse[previousResponse?.length - 1],
      animate: false,
    };

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: true,
    });

    setUserChatHistory(previousResponse);
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }

    const email = GetDecryptEmailformat();

    const reference_number = GetDecryptReferenceNumberformat();

    async function query() {
      try {
        let form_data = new FormData();
        form_data.append("prompt", aiagentinput);
        form_data.append("user_email", email);
        form_data.append("user_reference_number", reference_number);

        const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/image_generation`, {
          method: "POST",
          body: form_data,
        });

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.error,
          };

          setUserChatHistory(previousResponse);
          return;
        }

        if (result?.message === "Rate limit exceeded") {
          const updatedResponse = [...previousResponse];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: result.tips,
          };

          setUserChatHistory(previousResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.image_base64 || userchatHistory?.image,
          hasImage: true,
          showStatus: true,
        };

        setUserChatHistory(previousResponse);

        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }

        if (result?.status === 200) {
          const type = "image";
          dispatch(GetUserChatHistory({ dispatch, email, type }));
          setSelectedItem("image");
        }
      } catch (error) {
        toast.error(error);
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate response.",
        };

        setUserChatHistory(updatedResponse);
        const type = "image";
        dispatch(GetUserChatHistory({ dispatch, email, type }));
      }
    }

    query().then((response) => {
      return response;
    });
  };

  const HandleImageToVideo = (e) => {
    e?.preventDefault();
    setAiagentinput("");

    const email = GetDecryptEmailformat();

    const reference_number = GetDecryptReferenceNumberformat();

    let previousResponse = [...userchatHistory];

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: true,
    });

    setUserChatHistory(previousResponse);

    async function query() {
      try {
        const obj = {
          init_image: S3BucketImage ? S3BucketImage : "",
          image_prompt: aiagentinput,
          motion_bucket_id: 10,
          num_frames: 25,
          output_type: "mp4",
          user_email: email,
          user_reference_number: reference_number,
        };

        const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/mimage_video`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        });

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.ai_response?.response_text,
          };
          setUserChatHistory(previousResponse);

          return;
        }

        if (result?.message === "Rate limit exceeded") {
          const updatedResponse = [...previousResponse];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: result.tips,
          };
          setUserChatHistory(updatedResponse);

          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.future_links ? result?.future_links[0] : result?.output[0],
          hasVideo: true,
          showStatus: true,
        };

        setUserChatHistory(previousResponse);

        let dataurl = result?.future_links ? result?.future_links[0] : result?.output[0];

        pollForVideoAvailability(dataurl, previousResponse);
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }

        // if (result) {
        //   const type = "video";
        //   dispatch(GetUserChatHistory({ dispatch, email, type }));
        //   setSelectedItem("video");
        // }
      } catch (error) {
        toast.error("An error occurred: " + error.message);
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate response.",
        };
        setUserChatHistory(updatedResponse);
      }
    }

    query().then((response) => {
      return response;
    });
  };

  const HandleAudioToVideo = async () => {
    let previousResponse = [...userchatHistory];

    previousResponse.push({
      answer: "",
      hasAudio: true,
      userAudio: audioBase64,
    });

    setUserChatHistory(previousResponse);
    try {
      const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/voice_video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ audio: audioBase64 }),
      });

      const result = await response.json();

      if (result?.error) {
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: result.error,
        };

        setUserChatHistory(updatedResponse);
        return;
      }

      if (result?.message === "Rate limit exceeded") {
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: result.tips,
        };

        setUserChatHistory(updatedResponse);
        return;
      }

      previousResponse[previousResponse?.length - 1] = {
        ...previousResponse[previousResponse?.length - 1],
        answer: result?.future_links ? result?.future_links[0] : result?.output[0],
        hasVideo: true,
        hasAudio: true,
        animate: true,
      };

      let dataurl = result?.future_links ? result?.future_links[0] : result?.output[0];

      setUserChatHistory(previousResponse);

      pollForVideoAvailability(dataurl, previousResponse);

      if (answerScroll.current) {
        answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
      }
      // if (result) {
      //   const type = "video";
      //   dispatch(GetUserChatHistory({ dispatch, email, type }));
      //   setSelectedItem("video");
      // }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
      const updatedResponse = [...previousResponse];
      updatedResponse[updatedResponse.length - 1] = {
        ...updatedResponse[updatedResponse.length - 1],
        answer: "Failed to generate response.",
      };

      setUserChatHistory(updatedResponse);
    }
  };

  const HandleTexttoVideo = (e) => {
    e?.preventDefault();
    setAiagentinput("");

    const email = GetDecryptEmailformat();

    const reference_number = GetDecryptReferenceNumberformat();

    let previousResponse = [...userchatHistory];

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: false,
    });

    setUserChatHistory(previousResponse);
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }

    async function query() {
      try {
        let form_data = new FormData();
        form_data.append("prompt", aiagentinput);
        form_data.append("user_email", email);
        form_data.append("user_reference_number", reference_number);

        const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/modellab_video`, {
          method: "POST",
          body: form_data,
        });

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        console.log(result, "resssss");

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.error,
          };
          setUserChatHistory(previousResponse);

          return;
        }
        if (result?.message === "Rate limit exceeded") {
          const updatedResponse = [...previousResponse];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: result.tips,
          };
          setUserChatHistory(updatedResponse);

          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.future_links ? result?.future_links[0] : result?.output[0],
          hasVideo: true,
          animate: true,
          showStatus: true,
        };

        let dataurl = result?.future_links ? result?.future_links[0] : result?.output[0];

        setUserChatHistory(previousResponse);

        pollForVideoAvailability(dataurl);
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }

        // if (result) {
        //   const type = "video";
        //   dispatch(GetUserChatHistory({ dispatch, email, type }));
        //   setSelectedItem("video");
        // }
        setSelectedItem("video");
      } catch (error) {
        console.log(error, "errrrrr");
        toast.error(error);
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate response",
        };
        setUserChatHistory(updatedResponse);
      }
    }

    query().then((response) => {
      return response;
    });
  };

  return (
    <>
      <div className="Chat_bot_cssAiagent">
        <div className="d-flex justify-content-center">
          <h1 style={{ fontSize: "16px", fontWeight: "600" }}>ProjectW(AI)</h1>
        </div>
        <UserHistory
          userchatHistory={userchatHistory}
          setUserChatHistory={setUserChatHistory}
          answerRefs={answerRefs}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        />

        <div className="main-div-msg">
          <div className="input-box">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-lg-5">
                <div className="button-group">
                  <div className="first-btn">
                    <ul className="first-button">
                      <div className="dropdown my-3">
                        <Select
                          style={{ width: 250 }}
                          placeholder="Chat"
                          value={activeOption}
                          onChange={(value) => {
                            setActiveOption(value);
                            switch (value) {
                              case "video":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  video: true,
                                }));

                                dispatch(GetUserChatHistory({ dispatch, email, type: "video" }));
                                setSelectedItem("video");
                                break;
                              case "imagevideo":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  imagevideo: true,
                                }));

                                dispatch(GetUserChatHistory({ dispatch, email, type: "video" }));
                                setSelectedItem("video");
                                break;
                              case "audiovideo":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  audiovideo: true,
                                }));
                                dispatch(GetUserChatHistory({ dispatch, email, type: "video" }));
                                setSelectedItem("video");
                                break;
                              case "text":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  text: true,
                                }));
                                dispatch(GetUserChatHistory({ dispatch, email, type: "text" }));
                                setSelectedItem("text");
                                break;
                              case "image":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  image: true,
                                }));
                                dispatch(GetUserChatHistory({ dispatch, email, type: "image" }));
                                setSelectedItem("image");
                                break;
                              case "audio":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  audio: true,
                                }));
                                dispatch(GetUserChatHistory({ dispatch, email, type: "voice" }));
                                setSelectedItem("voice");
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          <Option value="text" className={activeOption === "text" ? "active-option" : ""}>
                            <FileTextOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "text" ? "#1890ff" : "inherit",
                              }}
                            />
                            {text || "Chat"}
                          </Option>
                          <Option value="image" className={activeOption === "image" ? "active-option" : ""}>
                            <PictureOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "image" ? "#1890ff" : "inherit",
                              }}
                            />
                            {imagetext || "Image"}
                          </Option>
                          <Option value="audio" className={activeOption === "audio" ? "active-option" : ""}>
                            <AudioOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "audio" ? "#1890ff" : "inherit",
                              }}
                            />
                            {audiotext || "Audio"}
                          </Option>
                          <Option value="video" className={activeOption === "video" ? "active-option" : ""}>
                            <VideoCameraOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "video" ? "#1890ff" : "inherit",
                              }}
                            />
                            {videotext || "Text to Video"}
                          </Option>
                          <Option value="imagevideo" className={activeOption === "imagevideo" ? "active-option" : ""}>
                            <VideoCameraFilled
                              style={{
                                marginRight: 8,
                                color: activeOption === "imagevideo" ? "#1890ff" : "inherit",
                              }}
                            />
                            {Imagetovideotext || "Image to Video"}
                          </Option>
                          <Option value="audiovideo" className={activeOption === "audiovideo" ? "active-option" : ""}>
                            <VideoCameraAddOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "audiovideo" ? "#1890ff" : "inherit",
                              }}
                            />
                            {Audiotovideotext || "Audio to Video"}
                          </Option>
                        </Select>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                {selecticon?.text ? (
                  <div className="input-group" style={{ alignItems: "center", width: "100%" }}>
                    <span className="input-group-text">
                      <i className="fa-regular fa-message"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control input_css_for_all_placeholder"
                      name="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && aiagentinput?.length > 0 && !isGeneratingResponse) {
                          HandleTexttoText();
                        }
                      }}
                      onChange={(e) => HandleChange(e)}
                      value={aiagentinput}
                      placeholder="Ask a question..."
                    />
                    <button className="btn btn-link" onClick={(e) => HandleTexttoText(e)} disabled={aiagentinput?.length == 0 ||  !isGeneratingResponse}>
                      <i className={`fa-regular fa-paper-plane ${aiagentinput?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                    </button>
                  </div>
                ) : selecticon?.audio ? (
                  <div className="d-flex justify-content-center recording_css">
                    <button onClick={isRecording ? stopRecording : startRecording}>
                      <i
                        style={{
                          color: isRecording ? "red" : "gray",
                          cursor: "pointer",
                        }}
                        className="fa-solid fa-microphone "
                      >
                        <span className="mx-2"> {isRecording ? "Stop Voice Chat" : "Start Voice Chat"}</span>
                      </i>
                    </button>
                  </div>
                ) : selecticon?.image ? (
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa-solid fa-image"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control input_css_for_all_placeholder"
                      name="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && aiagentinput?.length > 0) {
                          HandleTexttoImage();
                        }
                      }}
                      onChange={(e) => HandleChange(e)}
                      value={aiagentinput}
                      placeholder="Generate an image..."
                    />
                    <button className="btn btn-link" onClick={(e) => HandleTexttoImage(e)} disabled={aiagentinput?.length == 0}>
                      <i className={`fa-regular fa-paper-plane  ${aiagentinput?.length > 0 ? "chatai_icon_css" : ""} `}></i>
                    </button>
                  </div>
                ) : selecticon?.imagevideo ? (
                  <div className="input-group d-flex flex-column align-items-stretch">
                    <div className="mb-2 d-flex">
                      <Upload
                        name="image"
                        className="Upload_image_css"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={() => false}
                        onChange={({ fileList }) => HandleFileChange(fileList)}
                      >
                        <Button className="upload_btn_css" icon={<UploadOutlined />}>
                          Upload Image
                        </Button>
                      </Upload>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="input-group-text">
                        <i className="fa-regular fa-message"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control input_css_for_all_placeholder"
                        name="text"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && S3BucketImage && aiagentinput?.length > 0) {
                            HandleImageToVideo(e);
                          }
                        }}
                        onChange={(e) => HandleChange(e)}
                        value={aiagentinput}
                        placeholder="Generate Image to video..."
                      />
                      <button className="btn btn-link" onClick={(e) => HandleImageToVideo(e)} disabled={!S3BucketImage || aiagentinput.length === 0}>
                        <i className={`fa-regular fa-paper-plane ${aiagentinput.length > 0 ? "chatai_icon_css" : ""}`}></i>
                      </button>
                    </div>
                  </div>
                ) : selecticon?.audiovideo ? (
                  <div className="d-flex justify-content-center recording_css">
                    <button onClick={isRecording ? stopRecording : startRecording}>
                      <i
                        style={{
                          color: isRecording ? "red" : "gray",
                          cursor: "pointer",
                        }}
                        className="fa-solid fa-microphone "
                      >
                        <span className="mx-2"> {isRecording ? "Stop Voice Chat" : "Audio to Video"}</span>
                      </i>
                    </button>
                  </div>
                ) : (
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa-solid fa-video"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control input_css_for_all_placeholder"
                      name="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && aiagentinput?.length > 0) {
                          HandleTexttoVideo(e);
                        }
                      }}
                      onChange={(e) => HandleChange(e)}
                      value={aiagentinput}
                      placeholder="Generate a video..."
                    />
                    <button className="btn btn-link" onClick={(e) => HandleTexttoVideo(e)} disabled={aiagentinput?.length == 0}>
                      <i className={`fa-regular fa-paper-plane   ${aiagentinput?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="message_cssAiagent my-2" ref={answerScroll}>
          {userchatHistory?.map((item, index) => (
            <div className="wid-flx" key={index} style={{ width: "100%", maxWidth: "98%" }}>
              <div className="right-ms">
                <div className="Question_cssAiagent">
                  <p className="mx-3 my-2" style={{ color: "black" }}>
                    <Question
                      hasAudio={item?.hasAudio}
                      voiceprompt={item?.prompt ? item?.prompt : ""}
                      base64audio={item?.audio_base64}
                      userAudio={item?.userAudio}
                      question={item?.user_input?.text || item?.question || item?.prompt || ""}
                    />
                  </p>
                </div>
              </div>
              <div className="left-ms">
                <div
                  className="Answer_cssAiagent my-4"
                  ref={(el) => {
                    answerRefs.current[index] = el;
                  }}
                >
                  <img className="AI-image" src={ProjectWlogo} alt="" />
                  <Answer
                    answer={item?.response_text || item?.response || item?.answer || item?.prompt || item?.video_url || ""}
                    userImage={item?.image || ""}
                    userVideo={item?.video_url || ""}
                    userImageprompt={item?.prompt || ""}
                    audio={item?.audio}
                    uservoiceresponse={item?.audio_base64 || ""}
                    hasAudio={item?.hasAudio}
                    animateText={item?.animate}
                    hasImage={item?.hasImage}
                    hasVideo={item?.hasVideo}
                    status={item?.showStatus}
                    index={index}
                    answerRefs={answerRefs}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChatAI;
