import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Form, Typography, List, Space, Card, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SearchByMailWords } from "../../redux/auth/authSlice";
import { GetDecryptEmailformat } from "../../Components/Common/Common";

const { Title, Text } = Typography;

const Mail = () => {
  const dispatch = useDispatch();
  const [mailonchange, setMailonchange] = useState("");
  const [error, setError] = useState("");
  const { wordsData } = useSelector((state) => state?.counter);
  const email = GetDecryptEmailformat();

  const handleSearch = () => {
    if (!mailonchange.trim()) {
      setError("*Required");
      return;
    }

    const obj = {
      email: email,
      search_words: mailonchange,
    };

    dispatch(SearchByMailWords({ obj, dispatch }));

    // Clear input only after dispatch
    setMailonchange("");
    setError(""); // Clear error message on successful dispatch
  };

  return (
    <div style={{ padding: "24px" }}>
      <Row justify="center">
        <Col xs={24} sm={22} md={20} lg={18} xl={16}>
          <Card>
            <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
              Search by a Keyword in the Email Subject
            </Title>

            <Form layout="inline" onFinish={handleSearch}>
              <Form.Item validateStatus={error ? "error" : ""} help={error}>
                <Input
                  placeholder="Enter a keyword..."
                  value={mailonchange}
                  onChange={(e) => {
                    setMailonchange(e.target.value);
                    if (e.target.value.trim()) setError(""); // Clear error only if input is not empty
                  }}
                  suffix={<SearchOutlined />}
                  onPressEnter={(e) => {
                    e.preventDefault(); // Prevent form from submitting twice
                    handleSearch();
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                  Search
                </Button>
              </Form.Item>
            </Form>

            <div style={{ marginTop: "24px" }}>
              <Title level={4} style={{ marginBottom: "16px" }}>Search Results</Title>
              <List
                bordered
                dataSource={wordsData}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <Text>{item.split(/&nbsp;|,/).join(", ")}</Text>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Mail;
