import React, { useState } from "react";
import "../../../App.css";
import { Link, useLocation } from "react-router-dom";
import { FaInstagram, FaFileAlt, FaRocketchat, FaEnvelope, FaHouseUser, FaCaretLeft, FaCaretDown, FaTiktok, FaFileSignature, FaQuestionCircle, FaCreditCard } from "react-icons/fa";
import ProjectWlogo from "../../../Utils/Images/ProjectWlogo.svg";

const Sidebar = ({ HandleToggle }) => {
  const pathName = useLocation();
  // const [isProjectWOpen, setIsProjectWOpen] = useState(false);
  const [isProjectWOpentiktok, setIsProjectWOpenTikTok] = useState(false);

  // const toggleProjectWMenu = () => {
  //   setIsProjectWOpen(!isProjectWOpen);
  // };

  const toggleProjectWMenuTiktok = () => {
    setIsProjectWOpenTikTok(!isProjectWOpentiktok);
  };

  return (
    <>
      <div className="side-bar">
        <a className="abs-bk" onClick={HandleToggle}>
          <FaCaretLeft className="icon" />
        </a>
        <div className="logo-sec">
          <Link to="/dashboard">
            <img className="w-100 logo-top" src="images/logo.png" alt="" />
            <h1
              style={{
                color: "white",
                textAlign: "center",
                fontFamily: "serif",
                fontWeight: "600",
              }}
            >
              <img src={ProjectWlogo} alt="Logo" className="logo" />
            </h1>
          </Link>
        </div>
        <div className="link-div">
          <ul className="link-ul">
            <li>
              <Link to="/dashboard" className={`sidebar-link ${pathName.pathname === "/home" ? "active" : ""}`} title="Home">
                <FaHouseUser className="icons-svg" />
                Dashboard
              </Link>
            </li>

            <li>
              <Link to="/gmail" className={`sidebar-link ${pathName.pathname === "/gmail" ? "active" : ""}`} title="Gmail">
                <FaEnvelope className="icons-svg" />
                Gmail
              </Link>
            </li>

            <li>
              {/* <a onClick={toggleProjectWMenu} className={pathName.pathname.includes("/Chatai") ? "active" : ""} title="ProjectW(AI)">
                <FaRocketchat className="icons-svg" />
                ProjectW(AI)
                <FaCaretDown className="submenu-caret" />
              </a> */}
              {/* {isProjectWOpen && (
                <ul className="submenu"> */}
              <li>
                <Link to="/Chatai" className={`sidebar-link ${pathName.pathname === "/Chatai" ? "active" : ""}`} title="ProjectW(AI)">
                  <FaRocketchat className="icons-svg" />
                  ProjectW(AI)
                </Link>
              </li>
              {/* <li>
                    <Link to="/Chatbot" className={pathName.pathname === "/Chatbot" ? "active" : ""}>
                    <FaRocketchat className="icons-svg" />
                    ChatBot
                    </Link>
                  </li> */}
              {/* </ul>
              )} */}
            </li>

            <li>
              <Link to="/instagram" className={`sidebar-link ${pathName.pathname === "/instagram" ? "active" : ""}`} title="Instagram">
                <FaInstagram className="icons-svg" />
                Instagram
              </Link>
            </li>

            <li>
              <Link to="/privacypolicy" className={`sidebar-link ${pathName.pathname === "/privacypolicy" ? "active" : ""}`} title="Privacy&Policy">
                <FaFileAlt className="icons-svg" />
                Privacy & Policy
              </Link>
            </li>

            <li>
              <Link to="/termsandconditions" className={`sidebar-link ${pathName.pathname === "/termsandconditions" ? "active" : ""}`} title="Terms&Conditions">
                <FaFileSignature className="icons-svg" />
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link to="/faq" className={`sidebar-link ${pathName.pathname === "/faq" ? "active" : ""}`} title="Faq">
                <FaQuestionCircle className="icons-svg" />
                Faq
              </Link>
            </li>
            {/* <li>
              <Link to="/tiktok" className={pathName.pathname === "/tiktok" ? "active" : ""} title="Continue with TikTok">
                <FaTiktok className="icons-svg" />
                TikTok
              </Link>
            </li> */}

            <li>
              <a onClick={toggleProjectWMenuTiktok} className={`sidebar-link ${pathName.pathname.includes("/tiktok") ? "active" : ""}`} title="Continue with TikTok">
                <FaTiktok className="icons-svg" />
                TikTok
                <FaCaretDown className="submenu-caret" />
              </a>
              {isProjectWOpentiktok && (
                <ul className="submenu">
                  <li>
                    <Link to="/tiktok" className={`sidebar-link ${pathName.pathname === "/tiktok" ? "active" : ""}`} title="Continue with TikTok">
                      <FaTiktok className="icons-svg" />
                      Continue with Tiktok
                    </Link>
                  </li>
                  <li>
                    <Link to="/tiktokuserprofile" className={`sidebar-link ${pathName.pathname === "/tiktokuserprofile" ? "active" : ""}`} title="Continue with TikTok">
                      <FaTiktok className="icons-svg" />
                      Profile
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/Subscription" className={`sidebar-link ${pathName.pathname === "/Subscription" ? "active" : ""}`} title="Subscription">
                <FaCreditCard className="icons-svg" />
                Subscription
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
