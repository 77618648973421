import React, { useState, useEffect } from "react";
import { Card, Button, Tabs } from "antd";
import { CrownFilled, StarFilled, RocketFilled, ThunderboltFilled, EyeInvisibleFilled } from "@ant-design/icons";
import "../../style/admin.css";
import { useDispatch, useSelector } from "react-redux";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Components/Common/Common";
import { GetSubscriptionPlans } from "../../redux/auth/authSlice";
const { Meta } = Card;
const { TabPane } = Tabs;

const Subscription = () => {
  const [selectedTab, setSelectedTab] = useState("monthly");
  const [showBlackCard, setShowBlackCard] = useState(false);
  const dispatch = useDispatch();

  const email = GetDecryptEmailformat();  
  const referenceNumber = GetDecryptReferenceNumberformat();  

  const { subscriptionPlans } = useSelector((state) => state.counter);

  useEffect(() => {
    if (email && referenceNumber) {
      dispatch(GetSubscriptionPlans({ email, reference_number: referenceNumber }));
    }
  }, [email, referenceNumber, dispatch]);

  const getCardColor = (planName) => {
    switch (planName.toLowerCase()) {
      case "bronze tier":
        return "linear-gradient(135deg, #cd7f32, #a66a2a)";
      case "silver tier":
        return "linear-gradient(135deg, #c0c0c0, #a0a0a0)";
      case "gold tier":
        return "linear-gradient(135deg, #ffd700, #d4af37)";
      case "platinum":
        return "linear-gradient(135deg, #e5e4e2, #d3d3d3)";
      case "black":
        return "linear-gradient(135deg, #000000, #333333)";
      default:
        return "linear-gradient(135deg, #000000, #333333)";
    }
  };

  const getCardIcon = (planName) => {
    switch (planName.toLowerCase()) {
      case "bronze tier":
        return <StarFilled className="bronze-icon" />;
      case "silver tier":
        return <CrownFilled className="silver-icon" />;
      case "gold tier":
        return <RocketFilled className="gold-icon" />;
      case "platinum":
        return <ThunderboltFilled className="platinum-icon" />;
      case "black":
        return <EyeInvisibleFilled className="black-icon" />;
      default:
        return <EyeInvisibleFilled className="black-icon" />;
    }
  };

  const mappedCards = subscriptionPlans
    ?.filter((plan) => plan.is_deleted === 0)
    .map((plan) => {
      const price =
        selectedTab === "monthly" ? (plan.monthly_cost ? `$${plan.monthly_cost} | per month` : "Contact Us") : plan.yearly_cost ? `$${plan.yearly_cost} | per year` : "Contact Us";

      return {
        title: plan.name,
        color: getCardColor(plan.name),
        icon: getCardIcon(plan.name),
        description: plan.benefits,
        price: price,
        buttonText: "Payment",
        referenceNumber: plan.reference_number,
      };
    });

  return (
    <div className="subscription-container">
      <h1 className="subscription-heading">Subscription Plans</h1>

      <Tabs defaultActiveKey="monthly" centered onChange={(key) => setSelectedTab(key)} className="subscription-tabs">
        <TabPane tab="Monthly" key="monthly" />
        <TabPane tab="Yearly" key="yearly" />
      </Tabs>

      <div className="card-container">
        {mappedCards.map((card, index) => {
          if (card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" && !showBlackCard) {
            return null;
          }

          return (
            <Card key={index} hoverable className={`subscription-card ${card.title.toLowerCase()}-card`} style={{ background: card.color }}>
              <div className="card-icon">{card.icon}</div>
              <Meta
                title={<span className={`card-title ${card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" ? "black-card-text" : ""}`}>{card.title}</span>}
                description={
                  <div className="card-description">
                    <p className={`card-text ${card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" ? "black-card-text" : ""}`}>{card.description}</p>
                    <p className={`card-price ${card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" ? "black-card-text" : ""}`}>{card.price}</p>
                    <div className="card-buttons">
                      <Button type="primary" className={`card-button ${card.title.toLowerCase()}-button`}>
                        {card.buttonText}
                      </Button>
                    </div>
                  </div>
                }
              />
            </Card>
          );
        })}
      </div>

      {subscriptionPlans?.some((ele) => ele.reference_number === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7") && (
        <Button type="primary" className="toggle-black-card" onClick={() => setShowBlackCard((prev) => !prev)}>
          {showBlackCard ? "Hide Black Card" : "Reveal Black Card"}
        </Button>
      )}
    </div>
  );
};

export default Subscription;
